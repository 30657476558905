import { createSlice } from "@reduxjs/toolkit";

export const nourishmentSlice = createSlice({
  name: "nourishmentSlice",
  initialState: {
    selectedCard: undefined,
    allFieldsAreCompleted: false
  },
  reducers: {
    setSelectedCard: (state, action) => {
      state.selectedCard = action.payload;
    },
    setAllFieldsAreCompleted: (state, action) => {
      state.allFieldsAreCompleted = action.payload;
    }
  }
});

// Export Actions
export const { setSelectedCard, setAllFieldsAreCompleted } = nourishmentSlice.actions;
// Export Reducer
export const { reducer } = nourishmentSlice;
