import { StrictMode, Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./i18n/i18n";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { payPalClientId } from "./constants";

//Components
import { Spinner } from "./Components";
import App from "./App";

// Style
import { theme } from "./theme/theme";
import { ThemeProvider } from "@mui/material/styles";
import "./index.css";

// Redux Setup
import { Provider } from "react-redux";
import { store } from "./redux/store";

// UseQuery
import { QueryClient, QueryClientProvider } from "react-query";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
const queryClient = new QueryClient();

root.render(
    <StrictMode>
        <Provider store={store}>
            <Suspense fallback={<Spinner />}>
                <QueryClientProvider client={queryClient}>
                    <ThemeProvider theme={theme}>
                        <PayPalScriptProvider
                            options={{
                                "client-id": payPalClientId,
                                components: "buttons",
                                currency: "EUR",
                                "disable-funding": "card,giropay,sofort",
                                "enable-funding": "sepa"
                            }}>
                            <App />
                        </PayPalScriptProvider>
                    </ThemeProvider>
                </QueryClientProvider>
            </Suspense>
        </Provider>
    </StrictMode>
);
