import { createSlice } from "@reduxjs/toolkit";
import { InitialState } from "./userInformationSlice.types";

const initialState: InitialState = {
  gender: "",
  age: 0,
  height: undefined,
  weight: undefined,
  dailyActivityLevel: 0,
  freeTimeActivities: 0,
  hoursSliderValue: 0,
  allFieldsAreCompleted: false
};

export const userInformationSlice = createSlice({
  name: "userInformationSlice",
  initialState,
  reducers: {
    setGender: (state, action) => {
      state.gender = action.payload;
    },
    setAge: (state, action) => {
      state.age = action.payload;
    },
    setHeight: (state, action) => {
      state.height = action.payload;
    },
    setWeight: (state, action) => {
      state.weight = action.payload;
    },
    setDailyActivityLevel: (state, action) => {
      state.dailyActivityLevel = action.payload;
    },
    setFreeTimeActivities: (state, action) => {
      state.freeTimeActivities = action.payload;
    },
    setHoursSliderValue: (state, action) => {
      state.hoursSliderValue = action.payload;
    },
    setAllFieldsAreCompleted: (state, action) => {
      state.allFieldsAreCompleted = action.payload;
    }
  }
});

// Export action
export const {
  setGender,
  setAge,
  setHeight,
  setWeight,
  setDailyActivityLevel,
  setFreeTimeActivities,
  setHoursSliderValue,
  setAllFieldsAreCompleted
} = userInformationSlice.actions;
// Export reducer
export const { reducer } = userInformationSlice;
