import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import { reducer as homeReducer } from "./slices/homeSlice";
import { reducer as userInformationReducer } from "./slices/userInformationSlice";
import { reducer as nourishmentReducer } from "./slices/nourishmentSlice";
import { reducer as foodOptionsReducer } from "./slices/foodOptionsSlice";
import { reducer as dailyLifeReducer } from "./slices/dailyLifeSlice";
import { reducer as orderBookReducer } from "./slices/orderBookSlice";
import { reducer as ingredientTableReducer } from "./slices/ingredientTableSlice";
import { reducer as calorieCalculatorReducer } from "./slices/calorieCalculatorSlice";

export const store = configureStore({
  reducer: {
    home: homeReducer,
    userInformation: userInformationReducer,
    nourishment: nourishmentReducer,
    foodOptions: foodOptionsReducer,
    dailyLife: dailyLifeReducer,
    orderBook: orderBookReducer,
    ingredientTable: ingredientTableReducer,
    calorieCalculator: calorieCalculatorReducer
  }
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
