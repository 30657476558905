import { createSlice } from "@reduxjs/toolkit";

export const orderBookSlice = createSlice({
  name: "orderBookSlice",
  initialState: {
    firstName: "",
    lastName: "",
    billingDetails: {
      gender: "",
      givenName: "",
      surName: "",
      companyName: "",
      street: "",
      houseNumber: "",
      location: "",
      postalCode: "",
      country: "",
      email: ""
    },
    printBook: false,
    grossOrderPrice: "",
    netOrderPrice: "",
    netOriginPrice: "",
    paymentWidgetTitle: "",
    taxRate: NaN,
    termsAndConditionsCheckbox: false,
    productId: "",
    couponCode: "",
    couponId: NaN,
    couponAmount: "",
    orderId: "",
    isPaid: false,
    isOrderFinalized: false
  },
  reducers: {
    setFirstName: (state, action) => {
      state.firstName = action.payload;
    },
    setLastName: (state, action) => {
      state.lastName = action.payload;
    },
    setBillingDetails: (state, action) => {
      state.billingDetails = {
        ...state.billingDetails,
        ...action.payload
      };
    },
    setPrintBook: (state, action) => {
      state.printBook = action.payload;
      state.productId = action.payload ? "130425" : "130426";
    },
    setTaxRate: (state, action) => {
      state.taxRate = Number.parseFloat(action.payload)
    },
    setGrossOrderPrice: (state, action) => {
      const grossPrice = Number.parseFloat(action.payload)
      state.grossOrderPrice = grossPrice.toFixed(2);
      state.netOrderPrice = (grossPrice / ((100 + state.taxRate) / 100)).toFixed(2)
    },
    setAllPricesFromGrossPrice: (state, action) => {
      // Also sets orderPrice to non rebated price
      const grossPrice = Number.parseFloat(action.payload)
      const netPrice = (grossPrice / ((100 + state.taxRate) / 100)).toFixed(2)
      state.grossOrderPrice = grossPrice.toFixed(2);
      state.netOriginPrice = netPrice
      state.netOrderPrice = netPrice
    },
    setPaymentWidgetTitle: (state, action) => {
      state.paymentWidgetTitle = action.payload;
    },
    setTermsAndConditionsCheckbox: (state, action) => {
      state.termsAndConditionsCheckbox = action.payload;
    },
    setCouponAmount: (state, action) => {
      state.couponAmount = action.payload;
    },
    setCouponCode: (state, action) => {
      state.couponCode = action.payload;
    },
    setCouponId: (state, action) => {
      state.couponId = action.payload;
    },
    setOrderId: (state, action) => {
      state.orderId = action.payload;
    },
    setIsPaid: (state, action) => {
      state.isPaid = action.payload;
    },
    setIsOrderFinalized: (state, action) => {
      state.isOrderFinalized = action.payload;
    }
  }
});
// Export Actions
export const {
  setBillingDetails,
  setFirstName,
  setLastName,
  setPrintBook,
  setOrderId,
  setGrossOrderPrice,
  setAllPricesFromGrossPrice,
  setPaymentWidgetTitle,
  setTaxRate,
  setTermsAndConditionsCheckbox,
  setCouponAmount,
  setCouponCode,
  setCouponId,
  setIsPaid,
  setIsOrderFinalized
} = orderBookSlice.actions;
// Export Reducer
export const { reducer } = orderBookSlice;
