import { createSlice } from "@reduxjs/toolkit";

export const dailyLifeSlice = createSlice({
  name: "dailyLifeSlice",
  initialState: {
    takeoutFood: undefined,
    breakfastMinutes: "",
    lunchMinutes: "",
    dinnerMinutes: "",
    personsSliderValue: 1,
    allFieldsAreCompleted: false
  },
  reducers: {
    setTakeoutFoodAnswer: (state, action) => {
      state.takeoutFood = action.payload;
    },
    setBreakfastMinutes: (state, action) => {
      state.breakfastMinutes = action.payload;
    },
    setLunchMinutes: (state, action) => {
      state.lunchMinutes = action.payload;
    },
    setDinnerMinutes: (state, action) => {
      state.dinnerMinutes = action.payload;
    },
    setPersonsSliderValue: (state, action) => {
      state.personsSliderValue = action.payload;
    },
    setAllFieldsAreCompleted: (state, action) => {
      state.allFieldsAreCompleted = action.payload;
    }
  }
});

// Export action
export const {
  setTakeoutFoodAnswer,
  setBreakfastMinutes,
  setLunchMinutes,
  setDinnerMinutes,
  setPersonsSliderValue,
  setAllFieldsAreCompleted
} = dailyLifeSlice.actions;
// Export reducer
export const { reducer } = dailyLifeSlice;
