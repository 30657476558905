import { createSlice } from "@reduxjs/toolkit";
import { Intolerance } from "../../Pages/FoodOptions/types";

export const foodOptions = createSlice({
  name: "foodOptions",
  initialState: {
    intolerances: [] as Intolerance[],
    ingredients: {
      BREAD: undefined,
      FISH: undefined,
      SEAFOOD: undefined,
      POULTRY: undefined,
      BEEF: undefined,
      PORK: undefined,
      SOY: undefined,
      SMOOTHIE: undefined,
      SHAKE: undefined,
      EGG: undefined,
      YOGURT: undefined,
      POTATO: undefined,
      CEREAL: undefined,
      NOODLES: undefined,
      PIZZA: undefined,
      GRAIN: undefined,
      CASSEROLE: undefined,
      BAKED_GOODS_SWEET: undefined,
      BAKED_GOODS_HEARTY: undefined,
      WRAP: undefined
    },
    ingredientListExclusions: [],
    allFieldsAreCompleted: false
  },
  reducers: {
    setIntolerances: (state, action) => {
      state.intolerances = action.payload;
    },
    setIngredients: (state, action) => {
      type ObjectKey = keyof typeof state.ingredients;
      switch (action.payload.type) {
        case "GENERAL":
          for (const property in state.ingredients) {
            if (property === action.payload.key) {
              state.ingredients[property as ObjectKey] = action.payload.value;
            }
          }
          return state;
        case "RESET":
          for (const property in state.ingredients) {
            if (state.ingredients[property as ObjectKey] !== "EXCLUDED") {
              // An array which includes properties which should stay unchanged > VEGEN Soy := LIKE
              if (!action.payload.stayUnchanged.includes(property)) {
                state.ingredients[property as ObjectKey] = undefined;
              }
            }
          }
          return state;
        case "EXCLUDED_REST":
          for (const property in state.ingredients) {
            if (state.ingredients[property as ObjectKey] === undefined) {
              state.ingredients[property as ObjectKey] = "EXCLUDED" as any;
            }
          }
          return state;
        case "LIKE_REST":
          for (const property in state.ingredients) {
            if (state.ingredients[property as ObjectKey] === undefined) {
              state.ingredients[property as ObjectKey] = "LIKE" as any;
            }
          }
          return state;
        case "RESET_TO_INITIAL":
          for (const property in state.ingredients) {
            state.ingredients[property as ObjectKey] = undefined;
          }
          return state;
        default:
          return state;
      }
    },
    setIngredientListExclusions: (state, action) => {
      state.ingredientListExclusions = action.payload;
    },
    setAllFieldsAreCompleted: (state, action) => {
      state.allFieldsAreCompleted = action.payload;
    }
  }
});

// Export Actions
export const {
  setIntolerances,
  setIngredients,
  setIngredientListExclusions,
  setAllFieldsAreCompleted
} = foodOptions.actions;
// Export Reducer
export const { reducer } = foodOptions;
