import { createSlice } from "@reduxjs/toolkit";
import { IngredientCsvTable } from "../../shared/global.types";

export const ingredientTableSlice = createSlice({
  name: "ingredientTableSlice",
  initialState: {
    csvData: {
      data: [
        {
          Auschließbar: "",
          Ballaststoffe: "",
          EL: "",
          Eiweiss: "",
          Fett: "",
          Hauptausschluss: "",
          Haupteinheit: "",
          Hauptzutat: "",
          ID: "",
          Kalorien: "",
          Kategorie: "",
          Kohlenhydrate: "",
          Namensvarianten: "",
          Stueck: "",
          TL: "",
          Unvertraeglichkeit: "",
          newID: "",
          post_title: ""
        }
      ],
      error: [{ code: "", message: "", row: 0, type: "" }],
      meta: {
        delimiter: "",
        linienbreak: "",
        aborted: false,
        cursor: 0,
        fields: [""],
        truncated: false
      }
    } as IngredientCsvTable
  },
  reducers: {
    setCsvData: (state, action) => {
      state.csvData = action.payload;
    }
  }
});

// Export Actions
export const { setCsvData } = ingredientTableSlice.actions;
// Export Reducer
export const { reducer } = ingredientTableSlice;
