import { lazy } from "react";

const NavBar = lazy(() => import("./NavBar"));
const AlertMessage = lazy(() => import("./AlertMessage"));
const InputErrorHandler = lazy(() => import("./InputErrorHandler"));
const SliderThumbComponent = lazy(() => import("./SliderThumbComponent"));
const Footer = lazy(() => import("./Footer"));
const CalorieCalculator = lazy(() => import("./CalorieCalculator"));
const StepNavigation = lazy(() => import("./StepNavigation"));
const Step = lazy(() => import("./Step"));
const Spinner = lazy(() => import("./Spinner"));
const Alert = lazy(() => import("./Alert"));

export {
  NavBar,
  AlertMessage,
  InputErrorHandler,
  SliderThumbComponent,
  Footer,
  CalorieCalculator,
  StepNavigation,
  Step,
  Spinner,
  Alert
};
