import { useEffect } from "react";
import Papa, { ParseResult } from "papaparse";
import { IngredientCsvTable } from "../shared/global.types";

//redux
import { useAppDispatch } from "../redux/hooks";
import { setCsvData } from "../redux/slices/ingredientTableSlice";

export type LoadCsvDataProps = {
  /**
   * a text to be rendered as an alert message.
   */
  path: string;
};

export const LoadCsvData: React.FC<LoadCsvDataProps> = ({ path }: LoadCsvDataProps) => {
  const dispatchCsvData = useAppDispatch();
  useEffect(() => {
    /*
     * https://dev.to/mahdi_falamarzi/how-to-read-csv-file-in-typescript-react-app-106h
     * the .csv has to be inside the public folder.
     */
    Papa.parse(path, {
      delimiter: ";",
      header: true,
      download: true,
      complete: (results: ParseResult<IngredientCsvTable>) => {
        dispatchCsvData(setCsvData(results));
      }
    });
  }, []);

  return <></>;
};
