import { createSlice } from "@reduxjs/toolkit";

export const calorieCalculatorSlice = createSlice({
  name: "calorieCalculatorSlice",
  initialState: {
    caloriesUserNeedsToLoseDaily: undefined,
    userDailyCaloriesNeed: undefined,
    caloriesBurnedBySleeping: undefined,
    caloriesBurnedByWorking: undefined,
    caloriesBurnedByFreeTimeActivities: undefined,
    caloriesBurnedByDailyActivitiesWithoutSport: undefined,
    caloriesBurnedBySportDaily: undefined,
    totalCaloriesBurnedDaily: undefined
  },
  reducers: {
    setCaloriesUserNeedsToLoseDaily: (state, action) => {
      state.caloriesUserNeedsToLoseDaily = action.payload;
    },
    setUserDailyCaloriesNeed: (state, action) => {
      state.userDailyCaloriesNeed = action.payload;
    },
    setCaloriesBurnedBySleeping: (state, action) => {
      state.caloriesBurnedBySleeping = action.payload;
    },
    setCaloriesBurnedByWorking: (state, action) => {
      state.caloriesBurnedByWorking = action.payload;
    },
    setCaloriesBurnedByFreeTimeActivities: (state, action) => {
      state.caloriesBurnedByFreeTimeActivities = action.payload;
    },
    setCaloriesBurnedByDailyActivitiesWithoutSport: (state, action) => {
      state.caloriesBurnedByDailyActivitiesWithoutSport = action.payload;
    },
    setCaloriesBurnedBySportDaily: (state, action) => {
      state.caloriesBurnedBySportDaily = action.payload;
    },
    setTotalCaloriesBurnedDaily: (state, action) => {
      state.totalCaloriesBurnedDaily = action.payload;
    }
  }
});

// Export Actions
export const {
  setCaloriesUserNeedsToLoseDaily,
  setUserDailyCaloriesNeed,
  setCaloriesBurnedBySleeping,
  setCaloriesBurnedByWorking,
  setCaloriesBurnedByFreeTimeActivities,
  setCaloriesBurnedByDailyActivitiesWithoutSport,
  setCaloriesBurnedBySportDaily,
  setTotalCaloriesBurnedDaily
} = calorieCalculatorSlice.actions;
// Export Reducer
export const { reducer } = calorieCalculatorSlice;
