import { createSlice } from "@reduxjs/toolkit";

export const homeSlice = createSlice({
  name: "homeSlice",
  initialState: {
    weightGoal: "KEEP",
    weightLoss: 0,
    allFieldsAreCompleted: false
  },
  reducers: {
    setWeightGoal: (state, action) => {
      state.weightGoal = action.payload;
    },
    incrementWeightLossByAmount: (state, action) => {
      state.weightLoss = action.payload;
    },
    setAllFieldsAreCompleted: (state, action) => {
      state.allFieldsAreCompleted = action.payload;
    }
  }
});

// Export Actions
export const { setWeightGoal, incrementWeightLossByAmount, setAllFieldsAreCompleted } =
  homeSlice.actions;

// Export Reducer
export const { reducer } = homeSlice;
